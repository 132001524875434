import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-welcome',
  standalone: true,
  imports: [
    ButtonModule
  ],
  templateUrl: './welcome.component.html',
})
export class WelcomeComponent {
  name: string = '';
  email: string = '';
  verified: boolean = false;
  
  constructor(
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
  ) {
    if (this.dialogConfig.data?.email) {
      this.email = this.dialogConfig.data.email;
    }

    if (this.dialogConfig.data?.name) {
      this.name = this.dialogConfig.data.name;
    }

    if (this.dialogConfig.data?.verified) {
      this.verified = this.dialogConfig.data.verified;
    }
  }

  close() {
    this.dialogRef.close({ hasChanges: false });
  }
}
