<div style="text-align: center;">
  <img src="assets/riterz-icon.svg" alt="Riterz">
  <br><br>
  <h1>Welcome, {{ name }}</h1>
  @if (verified) {
    <p>
      We’re thrilled to have you onboard! Please start by 
      <a href="https://forms.riterz.com/t/m5qAM83uigus" target="_blank">submitting your company profile</a> 
      or feel free to explore the platform by clicking the button below.
      <br><br>
    </p>  
  } @else {
    <p>
      We’re thrilled to have you onboard! To get started, confirm your 
      email address by clicking the link in the email we sent to 
      <strong>{{ email }}</strong>. Check your spam folder if you 
      don’t see it.
      <br><br>
      You can <a href="https://forms.riterz.com/t/m5qAM83uigus" target="_blank">submit your profile</a> 
      now, but remember to verify your email to unlock all features.
      <br><br>
    </p>  
  }

</div>
<div style="display: flex; justify-content: flex-end; gap: 8px; margin-top: 12px;">
  <p-button
    (click)="close()"
    severity="primary"
    [text]="true"
    [outlined]="true"
    [rounded]="true"
    label="Continue"
  ></p-button>
</div>
