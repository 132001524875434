<div class="rtz-registration-wrap">
    <div class="rt-global-loading on" [class.on]="loadingGlobal">
      <div class="rt-spinner"></div>
    </div>

    <div class="rt-row">
      <div class="rt-column desk-6">

      </div>
      <div class="rt-column desk-6">
        <div class="rt-column-content">
          <div class="rt-auth-panel" *ngIf="!tokenError">
            @if (invitation) {
              <p>
                You have been invited to become a member of {{ invitation.organization?.name }} in
                Riterz platform. Please use an option below to continue.
              </p>        
            }
      
            @if (!emailRegistration) {
              <div class="rtz-reg-intro">
                <img src="assets/riterz-icon.svg" alt="Riterz" />
                <h1>Unlock full access</h1>
                <p>Join Riterz for free and elevate your brand partnerships. Please choose an option below to continue. </p>
              </div>
              <nav class="rtz-registration-options" [class.disabled]="!registrationForm.value.terms_and_conditions">
                <ul>
                  <li>
                    <a
                      href="{{ apiRoot }}../auth/socialite/google/redirect?return_url={{ returnUrl }}&invitation_token={{ invitation?.token }}"
                      class="ro-google"
                      title="Continue with Google"
                      >Continue with Google</a
                    >
                  </li>
                  <li>
                    <a
                      href="{{ apiRoot }}../auth/socialite/facebook/redirect?return_url={{ returnUrl }}&invitation_token={{ invitation?.token }}"
                      class="ro-fb"
                      title="Continue with Facebook"
                      >Continue with Facebook</a
                    >
                  </li>
                  <li>
                    <a
                      href="{{ apiRoot }}../auth/socialite/azure/redirect?return_url={{ returnUrl }}&invitation_token={{ invitation?.token }}"
                      class="ro-ms"
                      title="Continue with Microsoft"
                      >Continue with Microsoft</a
                    >
                  </li>
                  <li>
                    <a
                      (click)="emailRegistration = true"
                      class="ro-email rt-clickable"
                      title="Continue with Email"
                      >Continue with Email</a
                    >
                  </li>
                </ul>
              </nav>
              <br>     

              <form class="rt-column desk-12" [formGroup]="registrationForm">
                <div class="rt-column-content">
                  <div class="rt-checkbox-item">
                    <label>
                      <input
                        type="checkbox"
                        class="rt-touched"
                        formControlName="terms_and_conditions"
                      />
                      I have read and accepted the <a [href]="termsUrl" target="_blank">terms and conditions</a>.
                    </label>
                  </div>
                </div>
              </form>

              <div class="rt-column desk-12">
                <div class="rt-column-content">
                  <div class="rt-switch-auth">
                    Already have an account? <a (click)="toLogin()">Log in</a>
                  </div>
                </div>
              </div>
            
            } @else {
              <div class="rtz-reg-intro">
                <p-button
                  class="rt-auth-back"
                  (click)="emailRegistration = false"
                  icon="pi pi-arrow-left"
                  size="small"
                  severity="secondary"
                  [text]="true"
                  [rounded]="true"
                ></p-button>
                <img src="assets/riterz-icon.svg" alt="Riterz" />
                <h1>Unlock full access</h1>
              </div>
              <div class="rtz-reg-login">
                <form class="rt-row gap-8 propagate" [formGroup]="registrationForm">
                  <div class="rt-column desk-6 phab-12">
                    <div class="rt-column-content">
                      <label for="first_name">First name</label>
                      <input
                        [style]="{ width: '100%' }"
                        id="first_name"
                        formControlName="first_name"
                        pInputText
                      />
                      @if (
                        registrationForm.controls['first_name'].touched &&
                        registrationForm.controls['first_name'].hasError('required')
                      ) {
                        <div class="error-message">First name is required</div>
                      }
                    </div>
                  </div>
                  <div class="rt-column desk-6 phab-12">
                    <div class="rt-column-content">
                      <label for="last_name">Last name</label>
                      <input
                        [style]="{ width: '100%' }"
                        id="last_name"
                        formControlName="last_name"
                        pInputText
                      />
                      @if (
                        registrationForm.controls['last_name'].touched &&
                        registrationForm.controls['last_name'].hasError('required')
                      ) {
                        <div class="error-message">Last name is required</div>
                      }
                    </div>
                  </div>
                  <div class="rt-column desk-12 phab-12">
                    <div class="rt-column-content">
                      <label for="email">Email</label>
                      <input
                        [style]="{ width: '100%' }"
                        id="email"
                        type="email"
                        formControlName="email"
                        pInputText
                      />
                      @if (
                        registrationForm.controls['email'].touched &&
                        registrationForm.controls['email'].hasError('required')
                      ) {
                        <div class="error-message">Email is required</div>
                      }
                      @if (
                        registrationForm.controls['email'].touched &&
                        registrationForm.controls['email'].hasError('pattern')
                      ) {
                        <div class="error-message">Email is not in correct format</div>
                      }
                    </div>
                  </div>
                  <div class="rt-column desk-6 phab-12">
                    <div class="rt-column-content">
                      <label for="password">Password</label>
                      <input
                        [style]="{ width: '100%' }"
                        id="password"
                        type="password"
                        formControlName="password"
                        pInputText
                      />
                      @if (
                        registrationForm.controls['password'].touched &&
                        registrationForm.controls['password'].hasError('required')
                      ) {
                        <div class="error-message">Password is required</div>
                      }
                      @if (
                        registrationForm.controls['password'].touched &&
                        registrationForm.controls['password'].hasError('pattern')
                      ) {
                        <div class="error-message">Password is not in correct format</div>
                      }
                      @if (
                        registrationForm.controls['password'].touched &&
                        registrationForm.controls['password'].hasError('matching')
                      ) {
                        <div class="error-message">Passwords do not match</div>
                      }
                    </div>
                  </div>
                  <div class="rt-column desk-6 phab-12">
                    <div class="rt-column-content">
                      <label for="confirm_password">Confirm password</label>
                      <input
                        [style]="{ width: '100%' }"
                        id="confirm_password"
                        type="password"
                        formControlName="confirm_password"
                        pInputText
                      />
                      @if (
                        registrationForm.controls['confirm_password'].touched &&
                        registrationForm.controls['confirm_password'].hasError('required')
                      ) {
                        <div class="error-message">Password is required</div>
                      }
                      @if (
                        registrationForm.controls['confirm_password'].touched &&
                        registrationForm.controls['confirm_password'].hasError('pattern')
                      ) {
                        <div class="error-message">Password is not in correct format</div>
                      }
                      @if (
                        registrationForm.controls['confirm_password'].touched &&
                        registrationForm.controls['confirm_password'].hasError('matching')
                      ) {
                        <div class="error-message">Passwords do not match</div>
                      }
                    </div>
                  </div>
                  <div class="rt-column desk-12">
                    <div class="rt-column-content">
                      <p class="rtz-from-req">
                        Use 8 or more characters with a mix of letters, numbers &
                        symbols
                      </p>
                    </div>
                  </div>
                  <div class="rt-column desk-12">
                    <div class="rt-column-content">
                      <div class="rt-checkbox-item">
                        <label>
                          <input
                            type="checkbox"
                            class="rt-touched"
                            formControlName="terms_and_conditions"
                          />
                          I have read and accepted the <a [href]="termsUrl" target="_blank">terms and conditions</a>.
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
      
                <div class="rt-row">
                  <div class="rt-column desk-12">
                    <div class="rt-column-content">
                      <p-button
                        [style]="{ width: '100%' }"
                        [loading]="registering"
                        (click)="register()"
                        [disabled]="registrationForm.invalid"
                        severity="primary"
                        [rounded]="true"
                        label="Register"
                      />
                    </div>
                  </div>

                  <div class="rt-column desk-12">
                    <div class="rt-column-content">
                      <div class="rt-switch-auth">
                        Already have an account? <a (click)="toLogin()">Log in</a>
                      </div>
                    </div>
                  </div>
                </div>
      
                <p class="reg-footnote">
                  This site is protected by reCAPTCHA and the Google’s
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    title="Privacy Policy"
                  >Privacy Policy</a>
                  and
                  <a
                    href="https://policies.google.com/terms"
                    target="_blank"
                    title="Terms of Service"
                  >Terms of Service</a>
                  apply.
                </p>
              </div>
            }

            @if (tokenError) {
              <div class="rtz-reg-msg">
                <h1>{{ tokenError }}</h1>
                <p>
                  Please write to Riterz’s support team at<br />
                  <a href="mailto:support@riterz.com">support&#64;riterz.com</a>
                </p>
              </div>                          
            }
          </div>
        
        </div>
      </div>
    </div>

  </div>
  